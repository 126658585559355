let uniqueID = 1;
/* HOT MODULE SPECIFIC
 * Since hot reload blows away class instances, storage object is
 * moved outside of the class.
 */
let emptyData = {
    items:  [],
    summary: {
        updated: null,
        total_hours: 0,
    }
}
export class Store {
    constructor(name, callback) {
        this.data_error = {};
        this.loadRemote = true;
        this._load(name, callback);
    }
    _load(name, callback) {
        this._dbName = name;
        let localCache = localStorage.getItem(name);
        if (!localCache && this.loadRemote) {
            const token = localStorage.getItem("authCode") || 'invalid-authorization-token';
            fetch("/data/"+name, { method: 'GET', headers: { "Authorization": token} })
            .then(response => response.json())
            .then(remoteData => { 
                console.log('remoteData', remoteData); 
                const status = remoteData.status ? remoteData.status : 200; // assume success ???
                if (status > 399) {
                    throw new Error(`${remoteData.title}\n${remoteData.detail}\nError Code:${remoteData.status}`);
                }
                const data = remoteData ? remoteData : emptyData                
                localStorage.setItem(name, JSON.stringify(data));
                if (callback) {
                    callback(data);
                    return;
                }                    
            })
            .catch( error => {
                this.data_error = error;
                console.log("_load error :", error)
                return;
            });
        }
        if(!localCache){
            localStorage.setItem(name, JSON.stringify(emptyData));
            localCache = localStorage.getItem(name);
        }
        if (callback)
            callback(JSON.parse(localCache));
    }
    summary() {
        const data = JSON.parse(localStorage.getItem(this._dbName));
        return {
            total: data.summary.total_hours,
            update: data.summary.updated,
            count: data.items.length,
            error: this.data_error,
        }
    }
    load(date, callback) {
        var name = date.split("-", 2).join("_")
        this._load(name, callback);
    }
    upload(callback) {
        // does this method need a callback... what would be returned?
        const data = JSON.parse(localStorage.getItem(this._dbName));
        if (data.items.length && data.summary.total_hours) {
            const token = localStorage.getItem("authCode") || 'invalid-authorization-token';
            data.summary.updated = new Date().toISOString();
            const details = { status: 0, text: ''};
            fetch(`/${this._dbName}`, {
                mode: 'same-origin',
                method: "POST",
                headers: { "Content-Type": "application/json", "Authorization": token},
                body: JSON.stringify(data),
            })
            .then(response => { 
                details.text = `[${response.status}] ${response.statusText} `;
                details.status = response.status
                return response.text();
            })
            .then(text => { 
                let result = null;
                try {
                    result = JSON.parse(text)
                } catch (e) {
                    // suppress JSON parse errors                    
                }
                return (result) ? result : {
                    title: details.text,
                    detail: "n/a",
                    status: details.status,
                    raw: text
                }
            })
            .then(result => { 
                localStorage.setItem(this._dbName, JSON.stringify(data));
                if (result.status > 399) {
                    throw new Error(`${result.title}\n${result.detail}\nError Code:${result.status}`);
                }
                if (result.status < 299) {
                    this.data_error = {};
                    console.log("upload success", result); 
                }
            }) 
            .catch(error => {
                this.data_error = error;
                console.log("upload error: ", error)
            });
        }
    }
    clear() { localStorage.removeItem(this._dbName); }
    remove(filter) { 
        const data = JSON.parse(localStorage.getItem(this._dbName));
        let idx = data.items.findIndex(filter);
        data.items.splice(idx, 1);
        localStorage.setItem(this._dbName, JSON.stringify(data));
    }
    save(update, callback) {           
        const data = JSON.parse(localStorage.getItem(this._dbName));
        const total = (parseFloat(update.Maint)+parseFloat(update.Trades)) 
            + data.items.filter((o) => { return o.Type == "maint" })
                .reduce((t, o) => t + parseFloat(o.Maint), 0)
            + data.items.filter((o) => { return o.Type == "trade" })
                .reduce((t, o) => t + parseFloat(o.Trades), 0);

        data.summary.total_hours = total;
        data.items.push(update);
        localStorage.setItem(this._dbName, JSON.stringify(data));
        if (callback)
            callback(update);
    }
    findAll(callback) {
        if (!callback)
            return;
        return JSON.parse(localStorage.getItem(this._dbName)).items.filter(callback);
    }
}

export default Store;
