"use strict";

const htmlEscapes = {
    "&": "&amp",
    "<": "&lt",
    ">": "&gt",
    '"': "&quot",
    "'": "&#x27",
    "`": "&#x60",
};

const reUnescapedHtml = /[&<>"'`]/g;
const reHasUnescapedHtml = new RegExp(reUnescapedHtml.source);

const escape = (str) => str && reHasUnescapedHtml.test(str) ? str.replace(reUnescapedHtml, escapeHtmlChar) : str;
const escapeHtmlChar = (chr) => htmlEscapes[chr];
const _dateDiff = (date) => {
    if (!date) { return { sec: 0, hrs: 0, min: 0, more: false}; }
    const now = new Date();
    const then = new Date(date);
    const d = (now.getTime() - then.getTime())/1000;
    return {
        sec: d,
        hrs: Math.abs(Math. round(d / (60*60))),
        min: Math.abs(Math. round(d / 60)),
        more: (d > (60*60))
    }
}
const _lastUpdated = (stat) => {
    const diff =  _dateDiff(stat.update);
    const uom = (diff.more ? 'hrs' : 'mins')
    const value = (diff.more ? diff.hrs : diff.min);
    return `Updated ${(diff.sec < 120 ? 'just now' : `${value} ${uom} ago`)}`;
}
const createStatusItem = ({ total, update, count, ver, err }) => `
<div>
    <span class="badge text-bg-light">${total} hours</span>
    <span class="badge text-bg-dark">${count} items</span>
    <button class="show-alert"><span class="badge text-bg-light last-refresh">${update}</span>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" ${err ? 'style="display: inline"' : ""} fill="currentColor" class="bi bi-exclamation-triangle-fill text-warning" viewBox="0 0 16 16">
        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
    </svg>
    </button>
    <span class="badge text-bg-secondary">v${ver}</span>
    <div class="config">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
        </svg>
    </div>
</div>`;
const createWorkItem = ({ task, type, color, value }) => `
<li>
    <span class="badge text-bg-${color}" data-type="${type}">${value}</span>
    <div class="remove">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
        </svg>
    </div>
    <label>${task}</label>
</li>`;

class Template {
    constructor(version) {
        this.version = version;
    }

    typeInfo(item) { 
        let result = null;
        switch (item.Type) { // is there a better way ?
            case 'maint': result = {isPrefix: false, tag : 'hrs', color : "secondary", amount : item.Maint || ''}; break;
            case 'trade': result = {isPrefix: false, tag : 'hrs', color : "info", amount : item.Trades || ''}; break;
            case 'purch': result = {isPrefix: true, tag : '$ ', color : "success", amount : item.Purchase || ''}; break;
            default: break;        
        }
        result.value = result.isPrefix ? `${result.tag}${result.amount}` : `${result.amount}${result.tag}`
        return result;
    }

    showWorkItems(data) {
        let view = "";
        data.forEach((item) => {
            var info = this.typeInfo(item);
            view += createWorkItem({ 
                task: item.Task,
                type: item.Type,
                color: info.color,
                value: info.value,
            });
        });
        return view;
    }

    showStatus(stat) {
        return createStatusItem({
            total: stat.total,
            update: _lastUpdated(stat),
            count: stat.count,
            ver: this.version,
            err: stat.error.hasOwnProperty("message"),
        });
    }
}

export default Template;
