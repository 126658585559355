import View from "./view";
import Controller from "./controller";
import Model from "./model";
import Store from "./store";
import Template from "./template";

// Import all of Bootstrap's JS (if needed)
import * as bootstrap from 'bootstrap'

import './scss/styles.scss'
import "./app.css";


let workItems;
const onHashChange = () => {
    workItems.controller.setView(document.location.hash);
};

const onLoad = () => {
    const now = new Date();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const key = `${now.getFullYear()}_${month}`;
    workItems = new WorkItems(key); 
    onHashChange(); // ??
};

const APP_VERSION = "1.5"

function WorkItems(name) {
    this.dialog = new bootstrap.Modal('#config-dialog', {}); // ??
    this.storage = new Store(name);
    this.model = new Model(this.storage);
    this.template = new Template(APP_VERSION);
    this.view = new View(this.template, this.dialog);
    this.controller = new Controller(this.model, this.view);
}

/* HOT MODULE SPECIFIC */
if (module.hot) {
    module.hot.accept(function (err) {});
    if (document.readyState === "complete")
        onLoad();
}

window.addEventListener("load", onLoad);
window.addEventListener("hashchange", onHashChange);
