/* eslint no-invalid-this: 0, complexity:[2, 9] */
import { qs, qsa, $on, $parent, $delegate } from "./helpers";

const _taskTypeChange = (taskType) => {
    if (taskType === 'purch') {
        qs('#h').style.display = 'none';
        qs('#v').style.display = 'inline';
    } else {
        qs('#v').style.display = 'none';
        qs('#h').style.display = 'inline';
    }
}
const _toggleTheme = (element) => {
    const page = qs('html');
    const theme = page.dataset.bsTheme;
    page.dataset.bsTheme = element.className;
    qs(`.${theme}`).style.display = 'block';
    element.style.display = 'none';    
}
const _showAlert = (element, error) => {
    if (error.hasOwnProperty("message")) { 
        alert(`Unable to load data\n${error}`);
        const svg = element.classList.contains('last-refresh') ? element.nextElementSibling : (element.querySelector('svg') ? element.querySelector('svg') : element)
        svg.style.display = 'none';
        // (element.classList.contains('refresh') ? element.nextElementSibling : element).style.display = 'none';
    }
}
const _addOptions = (selector, data) => {
    const list = qs(selector)
    for (let k in data) {
        const opt = document.createElement("option");
        opt.setAttribute("id", k);
        opt.appendChild(document.createTextNode(data[k]));        
        list.appendChild(opt);
    };    
}
const ENTER_KEY = 13;
const ESCAPE_KEY = 27;

const SWIPE_THRESHOLD = 0.20 // 20 %
const INVERT_SWIPE_DIRECTION = false; 
// false : swipe left = previous date

export default class View {
    constructor(template, dialog) {
        this.template = template;
        this.dialog = dialog;
        this.touchstartX = 0;
        this.touchendX = 0;

        this.$title = qs("h1");
        this.$light = qs(".light");
        this.$dark = qs(".dark");

        this.$prev = qs(".prev");
        this.$when = qs(".when");
        this.$next = qs(".next");

        // this.$taskType = qs(".task-type");
        this.$categories = qs(".category");
        this.$addTask = qs(".common-what"); // naming ? could also use `qs('#what')`
        this.$addLocation = qs(".common-where"); // naming ? could also use `qs('#where')`
        this.$workItem = qs(".capture-task"); // also naming :-/
        this.$workItemList = qs(".capture-items"); // also naming :-/
        this.$amount = qs("input.amount");
        this.$done = qs(".done");

        this.$today = qs("#today");
        this.$status = qs(".status");
        this.$config = qs(".config");
        
        this.$saveConfig = qs("#save-config");
        this.$clearStorage = qs("#clear-storage");
        this.$authToken = qs("#auth-token");
        this.$alert = qs(".bi-exclamation-triangle")

        this.render = this.render.bind(this);
        this.bindCallback = this.bindCallback.bind(this);
    }

    _incrementDate (current, next) {
        this.$when.value = next;
        if (next === current) {
            this.$next.classList.add('disabled');
            this.$next.classList.replace('btn-primary', 'btn-outline-primary')
        } else {
            this.$next.classList.remove('disabled');
            this.$next.classList.replace('btn-outline-primary', 'btn-primary')
        }        
    }

    _addTaskToWorkItem (task) {
        this.$workItem.value += ` ${task} `;
        this.$workItem.focus();
        this.$addTask.selectedIndex = 0;            
    }

    _addLocationToWorkItem (location) {
        this.$workItem.value += ` [${location}] `;
        this.$workItem.focus()       
        this.$addLocation.selectedIndex = 0;
    }
    _amountUpdate(amount) {
        // no-op ???        
    }
    _workItemUpdate(content) {
        // no-op ???        
    }
    _displayWorkItems(list, stat) {
        this._clearInputs();
        this.$workItemList.innerHTML = this.template.showWorkItems(list);
        this.$status.innerHTML = this.template.showStatus(stat);
    }
    _clearInputs() {
        this.$workItem.value = "";
        this.$amount.value = 0;
        this.$done.classList.add("disabled");
        this.$done.disabled = true;
    }
    _changeTaskType(taskType, container) {
        qsa("label", this.$categories).forEach((element) => {
            ['btn-info', 'btn-secondary','btn-success'].forEach((className) => {
                element.classList.remove(className); });
        });
        let info = this.template.typeInfo({ Type: taskType });
        qs("label", container).classList.add("btn", `btn-${info.color}`);
        _taskTypeChange(taskType);
    }
    _resolveSwipeDirection() {
        let direction = 0;
        if (this.touchendX < this.touchstartX) { direction = -1; }
        if (this.touchendX > this.touchstartX) { direction = +1; }
        direction = (this.$next.classList.contains('disabled') && direction > 0) ? 0 : direction;

        let swipe = Math.abs(this.touchendX - this.touchstartX);
        direction = (swipe < (document.body.clientWidth * SWIPE_THRESHOLD)) ? 0 : direction;

        if (INVERT_SWIPE_DIRECTION) {
            direction = (direction < 0) ? +1 : -1;
        }
        return direction;
    }
    _buildWorkItem(element) {        
        let container = $parent(element, 'li');
        let span = qs('span', container);
        return {
            date : this.$when.value,
            value : span.textContent.trim(),
            type : span.dataset.type,
            content : qs('label', container).textContent.trim()
        }

    }
    // eslint-disable-next-line complexity
    render(viewCmd, parameter) {
        switch (viewCmd) {
            case "incrementDate":
                this._incrementDate(parameter.current, parameter.next);
                break;
            case "dateUpdate":
                this.$today.innerHTML = parameter.dateStamp;
                this.$workItemList.replaceChildren();
                this._displayWorkItems(parameter.list, parameter.stat)
                break;
            case "taskTypeChange":
                this._changeTaskType(parameter.taskType, parameter.container);
                break;
            case "addTaskToWorkItem":
                this._addTaskToWorkItem(parameter.task);
                break;
            case "addLocationToWorkItem":
                this._addLocationToWorkItem(parameter.location);
                break;
            case "enableDone":
                this.$done.classList.remove('disabled');
                this.$done.disabled = false;
                break;
            case "amountUpdate":
                this._amountUpdate(parameter.amount);
                break;
            case "workItemUpdate":
                this._workItemUpdate(parameter.content);
                break;
            case "displayWorkItems":
                this._displayWorkItems(parameter.list, parameter.stat)
                break;
            case "showConfig":
                this.$authToken.value = parameter.hasAuth ? 'placeholder-auth-token' : '';
                this.dialog.show();
                break;
            case "toggleTheme":
                _toggleTheme(parameter.element);
                break;
            case "showAlert":
                _showAlert(parameter.element, parameter.error);
                break;
            case "locations":
                _addOptions("#where", parameter.data);
                break;
            case "workItems":
                _addOptions("#what", parameter.data);
                break;
            case "startDate":
                this.$when.value = parameter.date;
                break
            }
    }

    bindCallback(event, handler) {
        switch (event) {
            case "incrementDate":
                $on(this.$prev, "click", (e) => { 
                    if (e.target.classList.contains('disabled'))
                        return;       
                    handler(`${this.$when.value} 00:00:01.234`, -1); 
                });
                $on(this.$next, "click", (e) => { 
                    if (e.target.classList.contains('disabled'))
                        return;       
                    handler(`${this.$when.value} 00:00:01.234`, +1); 
                });
                break;
            case "dateUpdate":
                $on(this.$when, "change", (e) => handler(this.$when.value));
                $on(this.$when, "blur", (e) => handler(this.$when.value)); // ??
                break;
            case "taskTypeChange":
                $delegate(this.$categories, 'input[type="radio"]', 'click', (e) => {
                    if (e.target.checked) {
                        const container = $parent(e.target, 'div');
                        handler(e.target.value, container);
                    } 
                });
                break;
            case "addTaskToWorkItem":
                $on(this.$addTask, "change", (e) => handler(this.$addTask.value));
                break;
            case "addLocationToWorkItem":
                $on(this.$addLocation, "change", (e) => handler(this.$addLocation.value));
                break;
            case "workItemUpdate": 
                $on(this.$workItem, "change", (e) => handler(this.$workItem.value));
                $on(this.$workItem, "blur", (e) => handler(this.$workItem.value)); //??
                break;
            case "amountUpdate":
                $on(this.$amount, "change", (e) => handler(this.$amount.value));
                $on(this.$amount, "blur", (e) => handler(this.$amount.value)); //??
                $on(this.$amount, "keypress", (e) => {
                    if (e.keyCode === ENTER_KEY) {
                        e.target.blur();
                    }
                });
                break;
            case "workItemSave":
                $on(this.$done, "click", (e) => handler(this.$when.value));
                break;
            case "workItemDelete":
                $delegate(this.$workItemList, ".remove", "click", (e) => {
                    let item = this._buildWorkItem(e.target);
                    handler(item); 
                });
                break;
            case "showConfig":
                $delegate(this.$status, ".config", 'click', (e) => { handler(e.target); });
                break;
            case "clearStorage":
                $on(this.$clearStorage, "click", (e) => { handler(e.target); });
                break;
            case "themeChange":
                $on(this.$dark, "click", (e) => { handler(e.target); });
                $on(this.$light, "click", (e) => { handler(e.target); });
                break;
            case "showAlert":
                $delegate(this.$status, ".bi-exclamation-triangle",  "click", (e) => { handler(e.target); });
                $delegate(this.$status, ".last-refresh",  "click", (e) => { handler( e.target ); });
                $delegate(this.$status, ".alert",  "click", (e) => { handler( e.target ); });
                break;
            case "pageSwipe":
                $on(document, 'touchstart', (e) => { 
                    this.touchstartX = e.changedTouches[0].screenX;
                });
                $on(document, 'touchend', (e) => { 
                    this.touchendX = e.changedTouches[0].screenX;
                    let direction = this._resolveSwipeDirection();
                    if (direction != 0)
                        handler(`${this.$when.value} 00:00:01.234`, direction); 
                });
                break;
            case "authUpdate":
                $on(this.$saveConfig, "click", (e) => { 
                    this.dialog.hide();
                    handler(this.$authToken.value); 
                });
                break;
        }
    }
}
